<template>
  <base-view-layout v-if="user && $i18n.locale == 'es'" >
    <template #breadcrumb>
      <li class="breadcrumb__nav-item breadcrumb__nav-item--back"><router-link :to="{ name: 'TeachersList' }"><img src="@/assets/images/icons/ico-chev-left--70L.svg"></router-link></li>
      <li class="breadcrumb__nav-item"><router-link :to="{ name: 'TeachersList' }">{{$t('teachershow.breadcrumb')}}</router-link></li>
      <li class="breadcrumb__nav-item" v-once>{{user.first_name}} {{user.last_name}}</li>
    </template>

    <template #abstract>{{$t('teachershow.title')}}</template>
    <template #title v-once>{{user.first_name}} {{user.last_name}}</template>
    <!--template #subtitle></template-->

    <section class="main-section">
      <h3 class="main-section__title">{{$t('teachershow.info')}}</h3>
      <div class="box box--sm box--no-shadow">
        <table class="data">
          <tbody>
            <tr>
              <td class="table-data"><p class="subtitle">{{$t('teachershow.correo')}}</p></td>
              <td><p>{{user.email}}</p></td>
            </tr>
            <tr>
              <td class="table-data"><p class="subtitle">{{$t('teachershow.nombre')}}</p></td>
              <td><p>{{user.first_name}} {{user.last_name}}</p></td>
            </tr>
            <tr>
              <td class="table-data"><p class="subtitle">{{$t('teachershow.sexo')}}</p></td>
              <td><p>{{user.sex}}</p></td>
            </tr>
            <tr>
              <td class="table-data"><p class="subtitle">{{$t('teachershow.fechanacimiento')}}</p></td>
              <td><p>{{user.birthdate}}</p></td>
            </tr>
            <tr>
              <td class="table-data"><p class="subtitle">{{$t('teachershow.telefono')}}</p></td>
              <td><p>{{user.phone}}</p></td>
            </tr>
            <tr>
              <td class="table-data"><p class="subtitle">{{$t('teachershow.direccion')}}</p></td>
              <td><p>{{user.address}}</p></td>
            </tr>
            <tr>
              <td class="table-data"><p class="subtitle">{{$t('teachershow.curso')}}</p></td>
              <td><p>{{user.grade_es}}</p></td>
            </tr>
            <tr>
              <td class="table-data"><p class="subtitle">{{$t('teachershow.colegio1')}}</p></td>
              <td><p>{{user.school_1}}</p></td>
            </tr>
            <tr>
              <td class="table-data"><p class="subtitle">{{$t('teachershow.colegio2')}}</p></td>
              <td><p>{{user.school_2}}</p></td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  </base-view-layout>

   <base-view-layout v-else-if="useren && $i18n.locale == 'en'" >
    <template #breadcrumb>
      <li class="breadcrumb__nav-item breadcrumb__nav-item--back"><router-link :to="{ name: 'TeachersList' }"><img src="@/assets/images/icons/ico-chev-left--70L.svg"></router-link></li>
      <li class="breadcrumb__nav-item"><router-link :to="{ name: 'TeachersList' }">{{$t('teachershow.breadcrumb')}}</router-link></li>
      <li class="breadcrumb__nav-item" v-once>{{useren.first_name}} {{useren.last_name}}</li>
    </template>

    <template #abstract>{{$t('teachershow.title')}}</template>
    <template #title v-once>{{useren.first_name}} {{useren.last_name}}</template>
    <!--template #subtitle></template-->

    <section class="main-section">
      <h3 class="main-section__title">{{$t('teachershow.info')}}</h3>
      <div class="box box--sm box--no-shadow">
        <table class="data">
          <tbody>
            <tr>
              <td class="table-data"><p class="subtitle">{{$t('teachershow.correo')}}</p></td>
              <td><p>{{useren.email}}</p></td>
            </tr>
            <tr>
              <td class="table-data"><p class="subtitle">{{$t('teachershow.nombre')}}</p></td>
              <td><p>{{useren.first_name}} {{useren.last_name}}</p></td>
            </tr>
            <tr>
              <td class="table-data"><p class="subtitle">{{$t('teachershow.sexo')}}</p></td>
              <td><p>{{useren.sex}}</p></td>
            </tr>
            <tr>
              <td class="table-data"><p class="subtitle">{{$t('teachershow.fechanacimiento')}}</p></td>
              <td><p>{{useren.birthdate}}</p></td>
            </tr>
            <tr>
              <td class="table-data"><p class="subtitle">{{$t('teachershow.telefono')}}</p></td>
              <td><p>{{useren.phone}}</p></td>
            </tr>
            <tr>
              <td class="table-data"><p class="subtitle">{{$t('teachershow.direccion')}}</p></td>
              <td><p>{{useren.address}}</p></td>
            </tr>
            <tr>
              <td class="table-data"><p class="subtitle">{{$t('teachershow.curso')}}</p></td>
              <td><p>{{useren.grade_en}}</p></td>
            </tr>
            <tr>
              <td class="table-data"><p class="subtitle">{{$t('teachershow.colegio1')}}</p></td>
              <td><p>{{useren.school_1}}</p></td>
            </tr>
            <tr>
              <td class="table-data"><p class="subtitle">{{$t('teachershow.colegio2')}}</p></td>
              <td><p>{{useren.school_2}}</p></td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  </base-view-layout>
</template>

<script>
import BaseViewLayout from '@/components/BaseViewLayout'
import sexes from '@/utils/sexes'

export default {
  name: 'TeachersShow',
  data () {
    return {
      user: null,
      useren:null,
      
    }
  },
  components: { BaseViewLayout },
  async created () {
    
    const user = (await this.$axios.get(`users/${this.$route.params.userId}`)).data.user
    user.sex = (sexes.es.find(sex => sex.id === user.sex) || {}).name
   
    const useren = (await this.$axios.get(`users/${this.$route.params.userId}`)).data.user
    useren.sex = (sexes.en.find(sex => sex.id === useren.sex) || {}).name

    const grades_es = (await this.$axios.get('grades-es')).data.grades_es
    user.grade_es = (grades_es.find(gradeid => gradeid.id === user.grade_id) || {}).name


    const grades_en = (await this.$axios.get('grades-en')).data.grades_en
    useren.grade_en = (grades_en.find(gradeid => gradeid.id === useren.grade_id) || {}).name

    
    if (user.birthdate) {
      user.birthdate = new Date(user.birthdate).toLocaleDateString('es-CL',{timeZone: 'UTC'})
    }
    this.user = user

    if (useren.birthdate) {
      useren.birthdate = new Date(useren.birthdate).toLocaleDateString('es-CL', {timeZone: 'UTC'})
    }
    this.useren = useren
  }
  
}
</script>
